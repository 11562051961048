import common from './modules/common.js'
import catalogs from './modules/catalogs.js'
import brigade from './modules/brigade.js'
import fishingPermit from './modules/fishing-permit.js'

import { api } from '../boot/axios'

function parseJwt(token) {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join(''),
	)

	return JSON.parse(jsonPayload)
}

function tokenTime(parsedToken) {
	const initedTime = localStorage.getItem('token_time')
	const currentTime = new Date().getTime()
	const substract = (currentTime - initedTime) / 1000
	const tokenLifeTime = parsedToken.exp - parsedToken.iat

	return tokenLifeTime - substract
}

function tokenExpired(parsedToken) {
	const time = tokenTime(parsedToken)

	return time <= 0
}

let timerId

export default {
	state: {
		access_token: '',
		refresh_token: '',
		notification: null,
		userObject: null,
		userRoles: [],
		sign: [],
	},
	getters: {
		userPermission: (state) => (roleList) => {
			if (!roleList || !roleList.length) return false
			let roleAccesses = state.userRoles.filter((element) => {
				return roleList.find((el) => {
					if (el == element) return true
				})
			})
			return roleAccesses.length ? true : false
		},
		getSign: (state) => {
			return state.sign
		},
		_getUserObject(state) {
			return state.userObject
		},
	},
	mutations: {
		setSign(state, payload) {
			state.sign.push(payload)
		},
		getUserRoles(state, roles) {
			state.userRoles = roles
		},
		SET_USER_OBJECT(state, user) {
			state.userObject = user
		},
		getUserNotifications(state, notification) {
			state.notification = notification
		},
		SET_ACCESS_TOKEN(state, payload) {
			state.access_token = payload
		},
		SET_REFRESH_TOKEN(state, payload) {
			state.refresh_token = payload
		},
	},
	actions: {
		logout({ commit }) {
			localStorage.removeItem('token')
			localStorage.removeItem('access_token')
			localStorage.removeItem('refresh_token')
			localStorage.removeItem('token_time')

			commit('SET_ACCESS_TOKEN', null)
			commit('SET_REFRESH_TOKEN', null)
			commit('setSign', [])
			commit('SET_USER_OBJECT', null)
			commit('getUserRoles', [])
		},
		set_tokens({ commit, dispatch }, payload) {
			if (timerId) clearTimeout(timerId)

			localStorage.setItem('token', 'Bearer ' + payload.access_token)
			localStorage.setItem('access_token', payload.access_token)
			localStorage.setItem('refresh_token', payload.refresh_token)
			localStorage.setItem('token_time', new Date().getTime())

			commit('SET_ACCESS_TOKEN', payload.access_token)
			commit('SET_REFRESH_TOKEN', payload.refresh_token)

			dispatch('setTimer')
		},
		setTimer({ dispatch, state }) {
			if (!state.access_token) return
			const parsedToken = parseJwt(state.access_token)
			timerId = setTimeout(() => {
				dispatch('fetchNewToken')
			}, tokenTime(parsedToken) * 1000)
		},
		fetchNewToken({ state, dispatch }) {
			api
				.post('/user/refreshToken', {
					refresh_token: state.refresh_token,
				})
				.then((response) => {
					if (response.data.token && !response.data.token.error) {
						dispatch('set_tokens', {
							access_token: response.data.token.access_token,
							refresh_token: response.data.token.refresh_token,
						})
					}
				})
				.catch(() => {
					// window.location = '/' + i18n.locale + '/login'
				})
		},
		loadFromLocal({ dispatch, commit }) {
			const access_token = localStorage.getItem('access_token')
			const refresh_token = localStorage.getItem('refresh_token')
			const token_time = localStorage.getItem('token_time')
			if (!access_token) return
			const parsedToken = parseJwt(access_token)

			if (tokenExpired(parsedToken)) {
				if (
					refresh_token &&
					Math.floor((new Date().getTime() - +token_time) / 1000) <
						+process.env.VUE_APP_REFRESH_TOKEN_TIME
				) {
					commit('SET_REFRESH_TOKEN', refresh_token)
					dispatch('fetchNewToken')
				} else {
					dispatch('logout')
				}
			} else {
				dispatch('set_tokens', {
					access_token,
					refresh_token,
				})
			}
		},
	},
	modules: {
		common,
		catalogs,
		brigade,
		fishingPermit,
	},
}
